import React, { useState, useEffect } from 'react';
import axios from 'cccisd-axios';
import PageHeader from 'js/components/PageHeader';
import starStatus from 'js/renders/StarStatus';
import userListModal from 'js/renders/UserListModal';
import report from 'js/renders/Report';
import Loader from 'cccisd-loader';
import Table from 'cccisd-graphql-table';
import schoolsQuery from './schools.graphql';

import IconCheck from 'cccisd-icons/checkmark';
import IconX from 'cccisd-icons/cross';
import IconWarn from 'cccisd-icons/notification2';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const EntityMySchools = () => {
    const [childGroupIds, setChildGroupIds] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const result = await axios.get(
                Boilerplate.route('api.shape.entity.schools', {
                    group: Fortress.user.acting.group.id,
                })
            );
            const groupIds = result.data.data.schools.map(d => d.groupId);
            setChildGroupIds(groupIds);
            setLoading(false);
        })();
    }, []);

    const columns = [
        {
            name: 'group.label',
            label: 'School',
        },
        {
            name: 'fields.entitySharing',
            label: 'Shares Data with Entity',
            className: 'text-center',
            render: ({ value, row }) => (value ? <IconCheck /> : <IconX />),
        },
        {
            name: 'fields.starStatus',
            label: 'Star Status',
            render: ({ value, row }) => starStatus()({ value, row }),
        },
        {
            name: 'descendantRoles.schoolAdminCount',
            label: 'Admins',
            render: ({ value, row }) =>
                userListModal({ users: 'descendantRoles.schoolAdminList', title: 'Admins' })({
                    value,
                    row,
                }),
        },
        {
            name: 'descendantRoles.schoolTeamMemberCount',
            label: 'Team Members',
            render: ({ value, row }) =>
                userListModal({
                    users: 'descendantRoles.schoolTeamMemberList',
                    title: 'Team Members',
                })({
                    value,
                    row,
                }),
        },
        {
            name: '',
            label: 'Mental Health Profile',
            render: ({ value, row }) =>
                report({
                    title: 'Mental Health Profile Report',
                    sharing: 'fields.entitySharing',
                    flowType: 'mhpReportSchool',
                })({
                    value,
                    row,
                }),
        },
        {
            name: '',
            label: 'Mental Health Quality Report',
            render: ({ value, row }) =>
                report({
                    title: 'Mental Health Quality Report',
                    sharing: 'fields.entitySharing',
                    flowType: 'mhqSchool',
                })({
                    value,
                    row,
                }),
        },
        {
            name: '',
            label: 'Trauma Responsiveness Report',
            render: ({ value, row }) =>
                report({
                    title: 'Trauma Responsiveness Report',
                    sharing: 'fields.entitySharing',
                    flowType: 'trs',
                })({
                    value,
                    row,
                }),
        },
        {
            name: '',
            label: 'OWBI-S Report',
            render: ({ value, row }) =>
                report({
                    title: 'OWBI-S Report',
                    sharing: 'fields.entitySharing',
                    flowType: 'owbiSchool',
                })({
                    value,
                    row,
                }),
        },
        {
            name: '',
            label: 'CARE Report',
            render: ({ value, row }) =>
                report({
                    title: 'CARE Report',
                    sharing: 'fields.entitySharing',
                    flowType: 'careSchool',
                    adminList: 'descendantRoles.schoolAdminList',
                    tmList: 'descendantRoles.schoolTeamMemberList',
                })({
                    value,
                    row,
                }),
        },
    ];

    if (loading) {
        return <Loader loading />;
    }

    if (childGroupIds.length < 1) {
        return (
            <div style={{ backgroundColor: '#308b85', color: '#fff', padding: '1em' }}>
                <IconWarn /> No schools are sharing data with this Entity.
            </div>
        );
    }

    return (
        <>
            <PageHeader />
            <div style={{ backgroundColor: '#308b85', color: '#fff', padding: '1em' }}>
                Monitor progress and view SHAPE reports from schools in your Entity.
            </div>
            <Table
                rowKey="group.groupId"
                query={schoolsQuery}
                graphqlVariables={{ groupIds: childGroupIds }}
                columns={columns}
            />
        </>
    );
};

export default EntityMySchools;
